import { FC } from 'react';
import { dataAttributeProps } from '../../../utils/ComponentUtils';
import IconProps from './IconProps';

const CheckMinusIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg
      {...dataAttributeProps(props)}
      onClick={onClick}
      className={`inline ${cursor} ${className}`}
      width="8"
      height="2"
      viewBox="0 0 8 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 1L8 1" stroke="currentColor" strokeWidth="1.33" strokeLinejoin="round" />
    </svg>
  );
};

export default CheckMinusIcon;
